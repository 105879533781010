import React, { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Container, Typography, Link } from "@mui/material"; 
import Logo from "../../components/Logo";
import OTPForm from "../../components/OTP";
import { motion } from "framer-motion";
import authentication from "../../services/AmplifyServices";
import "./style.css";

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const OTP = ({ setAuth }) => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);
  useEffect(() => {
    if (location.state === null) navigate("/login");
  }, []);

  const handleResend = () => {
    console.log(location.state.email);
    authentication.signIn(location.state.email);
  };
  return (
    <div className="root">
      <Container className="container" maxWidth="sm">
        <div className="card-content">
          <div className="topHeader">
            <img src="/static/1r-logo.png" alt="1receipt logo"/>
            <span>Enter your one-time password</span>
          </div>
          <div className="sub-content">
            <div className="heading" component={motion.div} {...fadeInUp}>
              <Logo />
              <Typography sx={{ color: "text.secondary", mb: 5 }}>
                Enter your one-time password sent to your email:{" "}
                {location.state && location.state.email}
              </Typography>
            </div>

            <OTPForm setAuth={setAuth} />

            <Typography
              component={motion.p}
              {...fadeInUp}
              variant="body2"
              align="center" 
              className="footer-text"
            >
              Need a new code?{" "}
              <Link
                variant="subtitle2"
                component={RouterLink}
                to=""
                state={location.state}
                onClick={handleResend}
                style={{ color: "#00C56E" }}
              >
                Resend
              </Link>
            </Typography>

            <br></br>

            <Typography
              component={motion.p}
              {...fadeInUp}
              variant="body2"
              align="center" 
              className="footer-text"
            >
              <i><b style={{color: "red", textDecoration: "underline"}}>IMPORTANT</b>: </i><i>Please also check your spam folder. If you find the email there, kindly mark it as “<b>Not Spam</b>” to ensure future messages reach your inbox.</i>
            </Typography>

            <br></br>

            <Typography
              component={motion.p}
              {...fadeInUp}
              variant="body2"
              align="center" 
              className="footer-text"
            >
              The code will self-destruct in 3 minutes.
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OTP;
